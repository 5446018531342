import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label, ReferenceDot } from 'recharts';
import './TakehomePayChart.css'; 

interface ChartProps {
  timeSeriesData: number[][];
  withdrawalRate: number;
  contributionsEndAge: string;
  contributionsEndAgePerson1: string;
  contributionsEndAgePerson2: string;
  withdrawalStartAge: string;
  withdrawalStartAgePerson1: string;
  withdrawalStartAgePerson2: string;
  currentAge: string;
  currentAgePerson1: string;
  currentAgePerson2: string;
  personsToggle: boolean
}

const SimplifiedRetirementIncomeChart: React.FC<ChartProps> = ({ 
    timeSeriesData, 
    withdrawalRate, 
    contributionsEndAge,
    contributionsEndAgePerson1,
    contributionsEndAgePerson2,
    withdrawalStartAge,
    withdrawalStartAgePerson1,
    withdrawalStartAgePerson2,
    currentAge,
    currentAgePerson1,
    currentAgePerson2,
    personsToggle
}) => {
  const formatDollar = (value: number) => {
    if (Math.abs(value) >= 1e9) {
      return `$${(value / 1e9).toFixed(1)}B`;
    } else if (Math.abs(value) >= 1e6) {
      return `$${(value / 1e6).toFixed(1)}M`;
    } else {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value);
    }
  };

  const calculateYield = (balance: number) => {
    return balance * (withdrawalRate / 100);
  };

  const CustomTooltip = ({ active, payload, label }: { active: boolean, payload: any, label: number }) => {
    if (active && payload && payload.length) {
      const totalBalance = payload.reduce((sum: number, entry: any) => sum + entry.value, 0);
      return (
        <div className="bg-gray-800 p-2 border border-gray-700 rounded shadow text-xs text-white">
          <p className="font-bold mb-1">{`Year: ${label}`}</p>
          {payload.map((entry: any, index: number) => (
            <div key={index} className="flex justify-between items-center mb-1">
              <span style={{ color: entry.color }}>{entry.name}:</span>
              <span className="font-semibold ml-2">{formatDollar(entry.value)}</span>
            </div>
          ))}
          <div className="text-xs mt-1">
            <span className="font-semibold">Annual Yield:</span>
            <span className="ml-2">{formatDollar(calculateYield(totalBalance))}</span>
          </div>
        </div>
      );
    }
    return null;
  };

  // Compute the number of years needed to include all events
  const timeUntilLastEvent = !personsToggle ?
  Math.max(parseInt(withdrawalStartAge), parseInt(contributionsEndAge)) - parseInt(currentAge) 
  : Math.max(
    Math.max(parseInt(withdrawalStartAgePerson1), parseInt(contributionsEndAgePerson1)) - parseInt(currentAgePerson1), 
    Math.max(parseInt(withdrawalStartAgePerson2), parseInt(contributionsEndAgePerson2)) - parseInt(currentAgePerson2)
  );

  // Continue the timeries 10 years afterthe last event
  const timeseriesLength = timeUntilLastEvent + 10; 

  // Create the chart data, filling in missing values with the last available value, since the 4% rule assumes a constant withdrawal rate that doesn't change the balance
  const chartData = Array.from({ length: timeseriesLength }, (_, index) => {
    const year = new Date().getFullYear() + index;
    const person1Balance = timeSeriesData[0][index] || timeSeriesData[0][timeSeriesData[0].length - 1] || 0;
    const person2Balance = timeSeriesData[1] 
      ? (timeSeriesData[1][index] || timeSeriesData[1][timeSeriesData[1].length - 1] || 0)
      : 0;

    return {
      year,
      person1Balance,
      person2Balance,
    };
  });

  const currentYear = new Date().getFullYear();

  const calculateYear = (age: string, currentAge: string) => {
    return currentYear + (parseInt(age) - parseInt(currentAge));
  };

  const addReferenceDot = (year: number, balance1: number, balance2: number) => (
    <ReferenceDot
      x={year}
      y={balance1 + balance2}
      r={5}
      fill="url(#shinyGoldGradient)" // Use the gradient defined below
      stroke="none"
    />
  );

  const addReferenceRectangle = (year: number, balance1: number, balance2: number) => (
    <ReferenceDot
      x={year}
      y={balance1 + balance2}
      r={7}  // Increased size
      fill="none"  // Transparent fill
      stroke="#A0A0A0"  // Light gray border
      strokeWidth={2}  // Thicker border
      shape={(props) => (
        <rect 
          {...props} 
          width={14} 
          height={14} 
          x={props.cx - 7} 
          y={props.cy - 7} 
          fill="none" 
          stroke="#A0A0A0" 
          strokeWidth={2}
        />
      )}
    />
  );

  return (
    <>
      <div className="chart-container">
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart data={chartData} margin={{ top: 10, right: 30, left: 70, bottom: 0 }}>
            <defs>
              <radialGradient id="shinyGoldGradient" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                <stop offset="0%" stopColor="#FFF7D6" /> {/* Light gold center */}
                <stop offset="50%" stopColor="#FFD700" /> {/* Pure gold */}
                <stop offset="100%" stopColor="#B8860B" /> {/* Darker gold edge */}
              </radialGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="year"
              tickFormatter={(year) => year.toString().substr(-2)}
              tick={{ fontSize: 12, fill: '#666' }}
            />
            <YAxis
              tickFormatter={(value) => formatDollar(value)}
              tick={{ fontSize: 12, fill: '#666' }}
            >
              <Label
                value="Retirement Savings (Today's Dollars)"
                angle={-90}
                position="insideLeft"
                style={{ textAnchor: 'middle', fill: '#999', fontSize: 14 }}
                dx={-30}
              />
            </YAxis>
            <Tooltip content={<CustomTooltip active={true} payload={[]} label={0} />} />
            <Area
              type="monotone"
              dataKey="person1Balance"
              stackId="1"
              stroke="#4CAF50"
              fill="#4CAF50"
              fillOpacity={0.6}
              name="Person 1"
            />
            {timeSeriesData[1] && (
              <Area
                type="monotone"
                dataKey="person2Balance"
                stackId="1"
                stroke="#2196F3"
                fill="#2196F3"
                fillOpacity={0.6}
                name="Person 2"
              />
            )}
            {personsToggle && (
              <>
                {/* Person 1 Reference Dots */}
                {addReferenceRectangle(
                  calculateYear(contributionsEndAgePerson1, currentAgePerson1),
                  chartData.find(d => d.year === calculateYear(contributionsEndAgePerson1, currentAgePerson1))?.person1Balance || 0,
                  0,
                )}
                {addReferenceDot(
                  calculateYear(withdrawalStartAgePerson1, currentAgePerson1),
                  chartData.find(d => d.year === calculateYear(withdrawalStartAgePerson1, currentAgePerson1))?.person1Balance || 0,
                  0,
                )}
                {/* Person 2 Reference Dots */}
                {addReferenceRectangle(
                  calculateYear(contributionsEndAgePerson2, currentAgePerson2),
                  chartData.find(d => d.year === calculateYear(contributionsEndAgePerson2, currentAgePerson2))?.person1Balance || 0,
                  chartData.find(d => d.year === calculateYear(contributionsEndAgePerson2, currentAgePerson2))?.person2Balance || 0,
                )}
                {addReferenceDot(
                  calculateYear(withdrawalStartAgePerson2, currentAgePerson2),
                  chartData.find(d => d.year === calculateYear(withdrawalStartAgePerson2, currentAgePerson2))?.person1Balance || 0,
                  chartData.find(d => d.year === calculateYear(withdrawalStartAgePerson2, currentAgePerson2))?.person2Balance || 0,
                )}
              </>
            )}

            {/* Single person scenario (using non-person-specific fields) */}
            {!personsToggle && (
              <>
                {addReferenceRectangle(
                  calculateYear(contributionsEndAge, currentAge),
                  chartData.find(d => d.year === calculateYear(contributionsEndAge, currentAge))?.person1Balance || 0,
                  0,
                )}
                {addReferenceDot(
                  calculateYear(withdrawalStartAge, currentAge),
                  chartData.find(d => d.year === calculateYear(withdrawalStartAge, currentAge))?.person1Balance || 0,
                  0,
                )}
              </>
            )}
          </AreaChart>
        </ResponsiveContainer>
      </div>
      <div className="chart-legend" style={{ marginTop: '10px', fontSize: '12px', color: '#999', textAlign: 'center' }}>
        <span style={{ marginRight: '15px' }}>
          <span style={{ 
            display: 'inline-block', 
            width: '12px', 
            height: '12px', 
            border: '2px solid #A0A0A0', 
            marginRight: '5px' 
          }}></span>
          Contributions End
        </span>
        <span>
          <span style={{ 
            display: 'inline-block', 
            width: '10px', 
            height: '10px', 
            background: 'radial-gradient(circle at 30% 30%, #FFF7D6, #FFD700 45%, #B8860B)', 
            borderRadius: '50%', 
            marginRight: '5px' 
          }}></span>
          Withdrawals Start
        </span>
      </div>
    </>
  );
};

export default SimplifiedRetirementIncomeChart;
