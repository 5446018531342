export const taxYearItems = {
    '2022': '2022',
    '2023': '2023',
    '2024': '2024',
};
export const stateItems = {
    'Alaska': 'Alaska',
    'Florida': 'Florida',
    'Georgia': 'Georgia',
    'New Hampshire': 'New Hampshire',
    'Nevada': 'Nevada',
    'South Dakota': 'South Dakota',
    'Tennessee': 'Tennessee',
    'Texas': 'Texas',
    'Wyoming': 'Wyoming',
};
export const filingStatuses = {
    'Married_Filing_Jointly': 'Married Filing Jointly',
    'Married_Filing_Separately': 'Married Filing Separately',
    'Single': 'Single',
};