import React, { useRef, useEffect } from 'react';
import Chart from 'chart.js/auto';
import './Chart.css';
import { FormData } from '../App';

interface ChartProps {
    labels: string[];
    chartData: number[][];
}

function formatCurrency(value: string | number) {
    const number = Number(value);
    return number.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,  // No decimal places
        maximumFractionDigits: 0,  // No decimal places
    });
}

const IncomeTaxChart: React.FC<ChartProps> = ({labels, chartData}) => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const chartInstance = useRef<Chart | null>(null);

  useEffect(() => {
    if (chartRef && chartRef.current) {
      const ctx = chartRef.current.getContext('2d');
      if (ctx) {
        if (chartInstance.current) {
            chartInstance.current.destroy();
        }
        chartInstance.current = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: labels,
                datasets: [{
                    label: 'Taxes Owed',
                    data: chartData[0],
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)', 
                        'rgba(255, 99, 132, 0.2)', 
                        'rgba(255, 99, 132, 0.2)', 
                        'rgba(255, 99, 132, 0.2)', 
                        'rgba(255, 99, 132, 0.6)',
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)', 
                        'rgba(255, 99, 132, 1)', 
                        'rgba(255, 99, 132, 1)', 
                        'rgba(255, 99, 132, 1)', 
                        'rgba(255, 99, 132, 1)',
                    ],
                    borderWidth: 1,
                },
            ],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: function(tooltipItem) {
                                return formatCurrency(Number(tooltipItem.raw))
                            },
                        }
                      },
                },
                scales: {
                    x: {
                        grid: {
                            display: false
                        },
                        ticks: {
                            font: {
                                size: 14
                            }
                        }
                    },
                    y: {
                        beginAtZero: true,
                        ticks: {
                            callback: formatCurrency,
                            font: {
                                size: 16,
                            }
                        }
                    },
                },
            },
          });
      }
    }
  }, [chartData, labels]);

  return (
    <div className="w-full h-full">
      <canvas ref={chartRef} className="w-full h-full"></canvas>
    </div>
  );
};

export default IncomeTaxChart;
