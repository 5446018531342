import React, { useState, useEffect, useRef } from 'react';
import { Card, CardContent, CardHeader } from '../../components/ui/card';
import { Input } from '../../components/ui/input';
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from '../../components/ui/select';
import { Button } from '../../components/ui/button';
import { Switch } from '../../components/ui/switch';
import { Label } from '../../components/ui/label';
import { cn } from "@/lib/utils";
import TakehomePayChart from '../../charts/TakehomePayChart';
import { IncomeTaxCalculatorAPIResponse } from '../../Types/Autogenerated/IncomeTaxCalculatorApiResponse';
import { IncomeTaxCalculatorRequestPayload } from '../../Types/Autogenerated/IncomeTaxCalculatorRequestPayload';
import { IncomesAdjustmentsAndDeductions } from '../../Types/Autogenerated/IncomesAdjustmentsAndDeductions';
import { Convert as FilingStatusConvert, FilingStatus } from '../../Types/Autogenerated/FilingStatus';
import { Convert as StateConvert } from '../../Types/Autogenerated/State';
import { FormData } from '../../App';
import { CurrencyInputOnChangeValues } from 'react-currency-input-field';
import { taxYearItems, stateItems, filingStatuses } from '../../Utils/Constants';
import { scrollToChart } from '../../Utils/chartUtils';


interface TakehomePayCalculatorPageProps {
  formData: FormData;
  selectedPage: string;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onValueChange: (value: string | undefined, name?: string, values?: CurrencyInputOnChangeValues) => void;
  handleToggleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setSnackbarMessage: (value: React.SetStateAction<string>) => void;
  showChart: boolean;
  showSnackbar: boolean;
  snackbarMessage: string;
  setShowChart: (value: React.SetStateAction<boolean>) => void;
  setChartData: (value: React.SetStateAction<number[][] | null>) => void;
  setShowSnackbar: (value: React.SetStateAction<boolean>) => void;
  chartData: number[][] | null;
  personsToggle: boolean;
  // Hanlder for when a select is changed
  handleSelectChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const TakehomePayCalculatorPage: React.FC<TakehomePayCalculatorPageProps> = ({
  formData,
  selectedPage,
  handleInputChange,
  onValueChange,
  handleToggleChange,
  setSnackbarMessage,
  showChart,
  showSnackbar,
  snackbarMessage,
  setShowChart,
  setChartData,
  setShowSnackbar,
  chartData,
  personsToggle,
  handleSelectChange,
  // ... other props
}) => {
  const [previousFilingStatus, setPreviousFilingStatus] = useState<FilingStatus>(FilingStatus.Single);
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Initialize previousFilingStatus when the component mounts
    setPreviousFilingStatus(formData.filingStatus as FilingStatus);
  }, []);

  useEffect(() => {
    if (showChart && chartData) {
      scrollToChart(chartRef);
    }
  }, [showChart, chartData]);

  const handleTwoIncomesToggle = (checked: boolean) => {
    // Call the handleToggleChange function with a synthesized event
    handleToggleChange({
      target: {
        name: 'personsToggle',
        checked,
        id: 'persons'  // For legacy reasons "persons" is the id for the toggle switch that controls 1 or 2 incomes
      }
    } as React.ChangeEvent<HTMLInputElement>);

    if (checked) {
      setPreviousFilingStatus(formData.filingStatus as FilingStatus);
      handleInputChange({ 
        target: { 
          name: 'filingStatus', 
          value: FilingStatus.MarriedFilingJointly.toString() 
        } 
      } as React.ChangeEvent<HTMLInputElement>);
    } else {
      handleInputChange({ 
        target: { 
          name: 'filingStatus', 
          value: previousFilingStatus.toString() 
        } 
      } as React.ChangeEvent<HTMLInputElement>);
    }
  };

  const handleFilingStatusChange = (newStatus: string) => {
    handleInputChange({ 
      target: { 
        name: 'filingStatus', 
        value: newStatus 
      } 
    } as React.ChangeEvent<HTMLInputElement>);
  };

  const handleStateChange = (newStatus: string) => {
    handleInputChange({ 
      target: { 
        name: 'state', 
        value: newStatus 
      } 
    } as React.ChangeEvent<HTMLInputElement>);
  };

  const handleTaxYearChange = (newStatus: string) => {
    handleInputChange({ 
      target: { 
        name: 'taxYear', 
        value: newStatus 
      } 
    } as React.ChangeEvent<HTMLInputElement>);
  };

  const formatNumber = (num: number | string) => {
    if (typeof num === 'string') {
      // Remove commas before parsing
      num = parseFloat(num.replace(/,/g, ''));
    }
    return isNaN(num) ? '' : num.toLocaleString('en-US');
  };

  const handleIncomeChange = (value: string, name: string) => {
    // Remove commas and convert to number
    const numericValue = value ? parseFloat(value.replace(/,/g, '')) : 0;
    onValueChange(numericValue.toString(), name);
  };

  const calculateTotalCharitableGiving = (): number[] => {
    if (!personsToggle) {
      return [(Number(formData.charitableGivingRate) * Number(formData.income)) / 100];
    } else {
      return [
        (Number(formData.charitableGivingRatePerson1) * Number(formData.incomePerson1)) / 100,
        (Number(formData.charitableGivingRatePerson2) * Number(formData.incomePerson2)) / 100
      ];
    }
  };

  const calculateTotalIncome = (): number => {
    return personsToggle 
      ? Number(formData.incomePerson1) + Number(formData.incomePerson2) 
      : Number(formData.income);
  };

  const makeIncomeTaxCalculatorUrl = () => {
    return window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
      ? 'http://127.0.0.1:5000/TakehomePayCalculator'
      : 'https://ykkgchfopnattrgul3quvlzbvm0qpmoc.lambda-url.us-west-2.on.aws/';
  };

  const makeAPIRequest = async () => {
    const url = makeIncomeTaxCalculatorUrl();
    const charitableGivings = calculateTotalCharitableGiving();

    const incomes_adjustments_and_deductions: IncomesAdjustmentsAndDeductions[] = !personsToggle
      ? [{
          salaries_and_wages: Number(formData.income),
          charitable_contributions: charitableGivings[0],
        }]
      : [{
          salaries_and_wages: Number(formData.incomePerson1),
          charitable_contributions: charitableGivings[0],
        }, {
          salaries_and_wages: Number(formData.incomePerson2),
          charitable_contributions: charitableGivings[1],
        }];

    const payload: IncomeTaxCalculatorRequestPayload = {
      tax_year: parseInt(formData.taxYear, 10),
      state: StateConvert.toState(JSON.stringify(formData.state)),
      filing_status: FilingStatusConvert.toFilingStatus(JSON.stringify(formData.filingStatus)),
      incomes_adjustments_and_deductions: incomes_adjustments_and_deductions,
      state_data: { exemptions: 0 }
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Unknown error occurred');
      }

      const data: IncomeTaxCalculatorAPIResponse = await response.json();
      setChartData(processChartData(data));
      setShowChart(true);
    } catch (error) {
      console.error('Error:', error);
      setSnackbarMessage(error instanceof Error ? error.message : 'An unknown error occurred');
      setShowSnackbar(true);
    }
  };

  const processChartData = (data: IncomeTaxCalculatorAPIResponse): number[][] => {
    const charitableGivings = calculateTotalCharitableGiving();
    const totalCharitableGiving = charitableGivings.reduce((sum, giving) => sum + giving, 0);
    const totalIncome = calculateTotalIncome();

    return [[
      totalIncome - (totalCharitableGiving + data.total_tax_owed),
      totalCharitableGiving,
      data.federal.income_tax_owed.reduce((a, b) => a + b, 0),
      data.social_security.income_tax_owed.reduce((a, b) => a + b, 0),
      data.state.income_tax_owed.reduce((a, b) => a + b, 0),
      data.medicare.income_tax_owed.reduce((a, b) => a + b, 0),
    ]];
  };

  const labels: string[] = [
    "Take-home Pay",
    "Charity",
    "Federal",
    "Social Security",
    "State",
    "Medicare",
  ];

  // Assuming taxYearItems is an object with year labels as keys and year values as values
  const sortedTaxYears = Object.entries(taxYearItems)
    .sort((a, b) => parseInt(b[1]) - parseInt(a[1])); // Sort in descending order

  if (showChart && chartData) {
    return (
      <div ref={chartRef} className="w-full h-screen flex flex-col pt-16 pb-4 px-1 sm:px-2 lg:px-3">
        <div className="flex-grow flex flex-col" style={{ maxHeight: 'calc(100vh - 150px)' }}>
          <div className="flex-grow">
            <TakehomePayChart labels={labels} chartData={chartData} />
          </div>
          <Button 
            className="w-full mt-4 bg-blue-600 hover:bg-blue-700 text-white" 
            onClick={() => setShowChart(false)}
          >
            Edit Assumptions
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="takehome-pay-calculator w-full px-1 sm:px-2 lg:px-3">
      <Card className="w-full">
        <CardHeader>
          <h2 className={cn("text-2xl font-bold")}>{selectedPage}</h2>
        </CardHeader>
        <CardContent>
          <div className={cn("space-y-4")}>
            <div className={cn("flex items-center justify-between")}>
              <Label htmlFor="personsToggle" className="text-left w-full">Two Incomes</Label>
              <Switch
                checked={personsToggle}
                onCheckedChange={handleTwoIncomesToggle}
              />
            </div>
            
            <div className={cn("grid grid-cols-2 gap-4")}>
              <div className={cn("space-y-4")}>
                <div>
                  <Label htmlFor={personsToggle ? "incomePerson1" : "income"} className="text-left w-full">
                    {personsToggle ? "Income 1" : "Income"}
                  </Label>
                  <div className={cn("relative")}>
                    <span className={cn("absolute inset-y-0 left-0 flex items-center pl-3 text-gray-500 text-base md:text-sm")}>$</span>
                    <Input
                      id={personsToggle ? "incomePerson1" : "income"}
                      name={personsToggle ? "incomePerson1" : "income"}
                      type="text"
                      value={formatNumber(personsToggle ? formData.incomePerson1 : formData.income)}
                      onChange={(e) => handleIncomeChange(e.target.value, personsToggle ? 'incomePerson1' : 'income')}
                      className={cn("pl-6")}
                    />
                  </div>
                </div>
                <div>
                  <Label htmlFor={personsToggle ? "charitableGivingRatePerson1" : "charitableGivingRate"} className="text-left w-full">
                    {personsToggle ? "Charitable Giving 1" : "Charitable Giving"}
                  </Label>
                  <div className={cn("relative")}>
                    <Input
                      id={personsToggle ? "charitableGivingRatePerson1" : "charitableGivingRate"}
                      name={personsToggle ? "charitableGivingRatePerson1" : "charitableGivingRate"}
                      type="number"
                      value={personsToggle ? formData.charitableGivingRatePerson1 : formData.charitableGivingRate}
                      onChange={handleInputChange}
                      className={cn("pr-8")}
                    />
                    <span className={cn("absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500 text-base md:text-sm")}>%</span>
                  </div>
                </div>
              </div>

              {personsToggle && (
                <div className={cn("space-y-4")}>
                  <div>
                    <Label htmlFor="incomePerson2" className="text-left w-full">Income 2</Label>
                    <div className={cn("relative")}>
                      <span className={cn("absolute inset-y-0 left-0 flex items-center pl-3 text-gray-500 text-base md:text-sm")}>$</span>
                      <Input
                        id="incomePerson2"
                        name="incomePerson2"
                        type="text"
                        value={formatNumber(formData.incomePerson2)}
                        onChange={(e) => onValueChange(e.target.value, 'incomePerson2')}
                        className={cn("pl-6")}
                      />
                    </div>
                  </div>
                  <div>
                    <Label htmlFor="charitableGivingRatePerson2" className="text-left w-full">Charitable Giving 2</Label>
                    <div className={cn("relative")}>
                      <Input
                        id="charitableGivingRatePerson2"
                        name="charitableGivingRatePerson2"
                        type="number"
                        value={formData.charitableGivingRatePerson2}
                        onChange={handleInputChange}
                        className={cn("pr-8")}
                      />
                      <span className={cn("absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500 text-base md:text-sm")}>%</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
            
            <div className={cn("grid grid-cols-2 gap-4")}>
              <div>
                <Label htmlFor="taxYear" className="text-left w-full">Tax Year</Label>
                <Select value={formData.taxYear} onValueChange={handleTaxYearChange}>
                  <SelectTrigger id="taxYear">
                    <SelectValue placeholder="Select year" />
                  </SelectTrigger>
                  <SelectContent>
                    {sortedTaxYears.map(([key, value]) => (
                      <SelectItem key={value} value={value}>{key}</SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              <div>
                <Label htmlFor="state" className="text-left w-full">State</Label>
                <Select value={formData.state} onValueChange={handleStateChange}>
                  <SelectTrigger id="state">
                    <SelectValue placeholder="Select state" />
                  </SelectTrigger>
                  <SelectContent>
                    {Object.entries(stateItems).map(([key, value]) => (
                      <SelectItem value={value}>{key}</SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            </div>
            
            <div>
              <Label htmlFor="filingStatus" className="text-left w-full">Filing Status</Label>
              <Select value={formData.filingStatus} onValueChange={handleFilingStatusChange} disabled={personsToggle}>
                <SelectTrigger id="filingStatus">
                  <SelectValue placeholder="Select filing status" />
                </SelectTrigger>
                <SelectContent>
                  {Object.entries(filingStatuses).map(([key, value]) => (
                    <SelectItem value={key}>{value}</SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            
            <Button className={cn("w-full")} onClick={makeAPIRequest}>Calculate Take-Home Pay</Button>
          </div>
        </CardContent>
        {showSnackbar && (
          <div className={cn("fixed bottom-4 left-4 right-4 bg-red-500 text-white p-4 rounded-md")}>
            {snackbarMessage}
          </div>
        )}
      </Card>
    </div>
  );
};

export default TakehomePayCalculatorPage;
