import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import ListSubheader from '@mui/material/ListSubheader';
import ShowChartIcon from '@mui/icons-material/ShowChart'; // Net Worth 
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'; // Retirement Income Calculator
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'; // Income Tax Calculator
import HomeIcon from '@mui/icons-material/Home'; // Take-Home Pay Calculator

import Typography from '@mui/material/Typography';

  // Maps of section-lists and their icons
  const calculatorsTextoToIconMap = {
    'Net Worth': <ShowChartIcon color="info" />,
    'Retirement Income': <AttachMoneyIcon color="info" />,
    'Income Tax': <AccountBalanceIcon color="info" />,
    'Take-Home Pay': <HomeIcon color="info" />,
  };

  const optimizersTextoToIconMap = {
    'After-Tax Retirement Income': <AttachMoneyIcon color="info" />,
  };

  const sections = {
    'Calculate': calculatorsTextoToIconMap,
    'Optimize': optimizersTextoToIconMap,
  }

const drawerWidth = 240;

interface ResponsiveDrawerProps {
    children?: React.ReactNode; // The children prop can be optional
    onListItemClick: (s: string) => void;
  }

const ResponsiveDrawer: React.FC<ResponsiveDrawerProps> = ({ children, onListItemClick }) => {

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const drawer = (
    <div>
      <Toolbar />
      {Object.entries(sections).map(([sectionName, textToIconMap]) => (
        <div>
            <Divider color="gray" />
            <ListSubheader  style={{ backgroundColor: 'inherit' }} color="inherit">{sectionName}</ListSubheader>
            <List>
            {Object.entries(textToIconMap).map(([text, icon]) => (
                <ListItem key={text} disablePadding>
                <ListItemButton onClick={() => onListItemClick(text)} key={text}>
                    <ListItemIcon>
                    {icon}
                    </ListItemIcon>
                    <ListItemText primary={text} />
                </ListItemButton>
                </ListItem>
            ))}
        </List>
        </div>
        ))}
    </div>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">MoneyMath</Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="calculator selector"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: '#282c34', color: 'white' },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: '#282c34', color: 'white'  },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 1, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        {/* Passed in content */}
        {children}
      </Box>
    </Box>
  );
}

export default ResponsiveDrawer;
