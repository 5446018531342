import React, { useEffect, useRef, useCallback } from 'react';
import { Card, CardContent, CardHeader } from '../../components/ui/card';
import { Input } from '../../components/ui/input';
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from '../../components/ui/select';
import { Button } from '../../components/ui/button';
import { Switch } from '../../components/ui/switch';
import { Label } from '../../components/ui/label';
import { cn } from "@/lib/utils";
import AfterTaxIncomeChart from '../../charts/AfterTaxIncomeChart';
import { AfterTaxRetirementIncomeOptimizerRequestPayload, RetirementFinancialInputs } from '../../Types/Autogenerated/AfterTaxRetirementIncomeOptimizerRequestPayload';
import { AfterTaxRetirementIncomeOptimizerAPIResponse } from '../../Types/Autogenerated/AfterTaxRetirementIncomeOptimizerApiResponse';
import { Convert as FilingStatusConvert } from '../../Types/Autogenerated/FilingStatus';
import { Convert as StateConvert } from '../../Types/Autogenerated/State';
import { FormData } from '../../App';
import { CurrencyInputOnChangeValues } from 'react-currency-input-field';
import { taxYearItems, stateItems, filingStatuses } from '../../Utils/Constants';
import { scrollToChart } from '../../Utils/chartUtils';


interface AfterTaxRetirementIncomeOptimizerPageProps {
    formData: FormData;
    selectedPage: string; // Should always be 'After-Tax Retirement Income' when this component is displayed
    personsToggle: boolean;
    adjustYearlyForInflationToggle: boolean;
    showSnackbar: boolean;
    snackbarMessage: string;
    showChart: boolean;
    chartData: number[][] | null;
    buttonStyle: { margin: number; };
    handleClose: (event: React.SyntheticEvent | Event, reason?: string) => void;
    setChartData: (value: React.SetStateAction<number[][] | null>) => void;
    setShowChart: (value: React.SetStateAction<boolean>) => void;
    handleClick: () => void;
    // Set error messges in the snackbar
    setSnackbarMessage: (value: React.SetStateAction<string>) => void;
    // Primary handler for handling the change in an input value
    handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    // onValueChange is used instead of onChange because of how the user-input is modified by CurrencyInput,
    // which seems to cause onChange to not always be triggered appriopriately as commas are shifted around
    onValueChange: (value: string | undefined, name?: string, values?: CurrencyInputOnChangeValues) => void;
    // Hanlder for when a toggle is changed
    handleToggleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    // Hanlder for when a select is changed
    handleSelectChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  }
    

const AfterTaxRetirementIncomeOptimizerPage: React.FC<AfterTaxRetirementIncomeOptimizerPageProps> = ({ 
    formData, 
    selectedPage,
    personsToggle, 
    adjustYearlyForInflationToggle, 
    showSnackbar,
    snackbarMessage,
    showChart,
    chartData,
    buttonStyle,
    handleClose,
    setChartData,
    setShowChart,
    handleClick,
    setSnackbarMessage, 
    handleInputChange, 
    onValueChange, 
    handleToggleChange,
    handleSelectChange,
}) => {
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (showChart && chartData) {
      scrollToChart(chartRef);
    }
  }, [showChart, chartData]);

  const formatNumber = (num: number | string) => {
    if (typeof num === 'string') {
      num = parseFloat(num.replace(/,/g, ''));
    }
    return isNaN(num) ? '' : num.toLocaleString('en-US');
  };

  const handleFinancialInputChange = (value: string, name: string) => {
    const numericValue = value ? parseFloat(value.replace(/,/g, '')) : 0;
    onValueChange(numericValue.toString(), name);
  };

  // Function to render inputs based on the number of persons
  const renderInputs = () => {
    return (
      <div className={cn("space-y-4 pb-4")}>
        <div className={cn("flex items-center justify-between")}>
          <Label htmlFor="personsToggle" className="text-left w-full">Two Incomes</Label>
          <Switch
            checked={personsToggle}
            onCheckedChange={(checked) => handleToggleChange({
              target: { name: 'personsToggle', checked, id: 'persons' }
            } as React.ChangeEvent<HTMLInputElement>)}
          />
        </div>

        <div className={cn("grid grid-cols-2 gap-4")}>
          <div className={cn("space-y-4")}>
            {renderFinancialInput(`Trad Balance${personsToggle ? ' 1' : ''}`, "initialTradBalance", personsToggle ? "initialTradBalancePerson1" : "initialTradBalance")}
            {renderFinancialInput(`Roth Balance${personsToggle ? ' 1' : ''}`, "initialRothBalance", personsToggle ? "initialRothBalancePerson1" : "initialRothBalance")}
            {renderFinancialInput(`Brokerage Balance${personsToggle ? ' 1' : ''}`, "initialBrokerageBalance", personsToggle ? "initialBrokerageBalancePerson1" : "initialBrokerageBalance")}
            {renderFinancialInput(`Yearly Trad 401k${personsToggle ? ' 1' : ''}`, "yearlyTrad401k", personsToggle ? "yearlyTrad401kPerson1" : "yearlyTrad401k")}
            {renderFinancialInput(`Yearly Roth 401k${personsToggle ? ' 1' : ''}`, "yearlyRoth401k", personsToggle ? "yearlyRoth401kPerson1" : "yearlyRoth401k")}
            {renderFinancialInput(`Yearly 401k Match${personsToggle ? ' 1' : ''}`, "yearlyTrad401kCompanyMatch", personsToggle ? "yearlyTrad401kCompanyMatchPerson1" : "yearlyTrad401kCompanyMatch")}
            {renderFinancialInput(`Yearly AfterTax 401k${personsToggle ? ' 1' : ''}`, "yearlyAfterTax401k", personsToggle ? "yearlyAfterTax401kPerson1" : "yearlyAfterTax401k")}
            {renderFinancialInput(`Yearly Trad IRA${personsToggle ? ' 1' : ''}`, "yearlyTradIRA", personsToggle ? "yearlyTradIRAPerson1" : "yearlyTradIRA")}
            {renderFinancialInput(`Yearly Roth IRA${personsToggle ? ' 1' : ''}`, "yearlyRothIRA", personsToggle ? "yearlyRothIRAPerson1" : "yearlyRothIRA")}
            {renderFinancialInput(`Yearly Brokerage${personsToggle ? ' 1' : ''}`, "yearlyBrokerage", personsToggle ? "yearlyBrokeragePerson1" : "yearlyBrokerage")}
            {renderFinancialInput(`Income${personsToggle ? ' 1' : ''}`, "income", personsToggle ? "incomePerson1" : "income")}
            {renderNumberInput(`Current Age${personsToggle ? ' 1' : ''}`, "currentAge", personsToggle ? "currentAgePerson1" : "currentAge")}
            {renderNumberInput(`Stop Savings Age${personsToggle ? ' 1' : ''}`, "contributionsEndAge", personsToggle ? "contributionsEndAgePerson1" : "contributionsEndAge")}
            {renderNumberInput(`Retirement Age${personsToggle ? ' 1' : ''}`, "withdrawalStartAge", personsToggle ? "withdrawalStartAgePerson1" : "withdrawalStartAge")}
          </div>

          {personsToggle && (
            <div className={cn("space-y-4")}>
              {renderFinancialInput("Trad Balance 2", "initialTradBalancePerson2", "initialTradBalancePerson2")}
              {renderFinancialInput("Roth Balance 2", "initialRothBalancePerson2", "initialRothBalancePerson2")}
              {renderFinancialInput("Brokerage Balance 2", "initialBrokerageBalancePerson2", "initialBrokerageBalancePerson2")}
              {renderFinancialInput("Yearly Trad 401k 2", "yearlyTrad401kPerson2", "yearlyTrad401kPerson2")}
              {renderFinancialInput("Yearly Roth 401k 2", "yearlyRoth401kPerson2", "yearlyRoth401kPerson2")}
              {renderFinancialInput("Yearly 401k Match 2", "yearlyTrad401kCompanyMatchPerson2", "yearlyTrad401kCompanyMatchPerson2")}
              {renderFinancialInput("Yearly AfterTax 401k 2", "yearlyAfterTax401kPerson2", "yearlyAfterTax401kPerson2")}
              {renderFinancialInput("Yearly Trad IRA 2", "yearlyTradIRAPerson2", "yearlyTradIRAPerson2")}
              {renderFinancialInput("Yearly Roth IRA 2", "yearlyRothIRAPerson2", "yearlyRothIRAPerson2")}
              {renderFinancialInput("Yearly Brokerage 2", "yearlyBrokeragePerson2", "yearlyBrokeragePerson2")}
              {renderFinancialInput("Income 2", "incomePerson2", "incomePerson2")}
              {renderNumberInput("Current Age 2", "currentAgePerson2", "currentAgePerson2")}
              {renderNumberInput("Stop Savings Age 2", "contributionsEndAgePerson2", "contributionsEndAgePerson2")}
              {renderNumberInput("Retirement Age 2", "withdrawalStartAgePerson2", "withdrawalStartAgePerson2")}
            </div>
          )}
        </div>

        <div className={cn("flex items-center justify-between")}>
          <Label htmlFor="adjustYearlyForInflationToggle" className="text-left w-full">Grow Contributions With Inflation</Label>
          <Switch
            checked={adjustYearlyForInflationToggle}
            onCheckedChange={(checked) => handleToggleChange({
              target: { name: 'adjustYearlyForInflationToggle', checked, id: 'adjustYearlyForInflationToggle' }
            } as React.ChangeEvent<HTMLInputElement>)}
          />
        </div>

        {renderPercentageInput("Withdrawal Rate", "withdrawalRate")}
        {renderPercentageInput("Average Annual Return", "averageAnnualReturn")}
        {renderPercentageInput("Inflation Rate", "inflationRate")}

        {renderDropdown("Tax Year", "taxYear", taxYearItems)}
        {renderDropdown("State", "state", stateItems)}
        {renderDropdown("Filing Status", "filingStatus", filingStatuses)}

        <Button className={cn("w-full")} onClick={handleCalculate} onTouchStart={(e) => {
          e.preventDefault();
          handleCalculate();
        }}>Show Projections</Button>
      </div>
    );
  };

  const renderFinancialInput = (label: string, name: string, formDataKey: string) => (
    <div>
      <Label htmlFor={name} className="text-left w-full">{label}</Label>
      <div className={cn("relative")}>
        <span className={cn("absolute inset-y-0 left-0 flex items-center pl-3 text-gray-500 text-base md:text-sm")}>$</span>
        <Input
          id={name}
          name={name}
          type="text"
          value={formatNumber(String(formData[formDataKey as keyof FormData]))}
          onChange={(e) => handleFinancialInputChange(e.target.value, formDataKey)}
          className={cn("pl-6")}
        />
      </div>
    </div>
  );

  const renderNumberInput = (label: string, name: string, formDataKey: string) => (
    <div>
      <Label htmlFor={name} className="text-left w-full">{label}</Label>
      <Input
        id={name}
        name={name}
        type="number"
        value={String(formData[formDataKey as keyof FormData])}
        onChange={handleInputChange}
      />
    </div>
  );

  const renderPercentageInput = (label: string, name: string) => (
    <div>
      <Label htmlFor={name} className="text-left w-full">{label}</Label>
      <div className={cn("relative")}>
        <Input
          id={name}
          name={name}
          type="number"
          value={String(formData[name as keyof FormData])}
          onChange={handleInputChange}
          className={cn("pr-8")}
        />
        <span className={cn("absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500 text-base md:text-sm")}>%</span>
      </div>
    </div>
  );

  const sortedTaxYears = Object.entries(taxYearItems)
    .sort((a, b) => parseInt(b[1]) - parseInt(a[1]));

  const renderDropdown = (label: string, name: string, options: Record<string, string>) => (
    <div>
      <Label htmlFor={name} className="text-left w-full">{label}</Label>
      <Select 
        value={formData[name as keyof FormData] as string}
        onValueChange={(value) => handleSelectChange({ target: { name, value } } as React.ChangeEvent<HTMLSelectElement>)}
      >
        <SelectTrigger id={name} name={name}>
          <SelectValue placeholder={`Select ${label}`} />
        </SelectTrigger>
        <SelectContent>
          {name === 'taxYear'
            ? sortedTaxYears.map(([key, value]) => (
                <SelectItem key={value} value={value}>{key}</SelectItem>
              ))
            : Object.entries(options).map(([value, label]) => (
                <SelectItem key={value} value={value}>{label}</SelectItem>
              ))
            }
        </SelectContent>
      </Select>
    </div>
  );

  const labels: string[] = [
    "Income Roth",
    "Income Trad",
    "Income Brokerage",
    "Income Post Tax"
  ];  

  // Function to fetch and parse the data
  async function fetchData(url: string, fetchOptions: any): Promise<AfterTaxRetirementIncomeOptimizerAPIResponse> {

    const response = await fetch(url, fetchOptions);
    if (!response.ok) {

      const errorData = await response.json();
      const errorMessage: string = errorData.error || 'Unknown error occurred'
      setSnackbarMessage(errorMessage)
      throw new Error(errorMessage);
    }

    const data = await response.json();

    // TODO: Validate the data here if necessary

    // Assert that the data is of the ApiResponse type
    return data as AfterTaxRetirementIncomeOptimizerAPIResponse;
  }
  const makeAfterTaxIncomeOptimizerUrl = () => {

    let baseUrl = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1' 
    ? 'http://127.0.0.1:5000/RetirementOptimizer' 
    : 'https://fn6futcy62b4zujekywsemx6ca0ejqwn.lambda-url.us-west-2.on.aws/';

    return baseUrl
}


const makeAfterTaxIncomeOptimizerPayload = (formData: FormData)  => {

  let retirementFinancialInputs: RetirementFinancialInputs[] = !personsToggle
  ? [{
    initial_roth_balance: parseFloat(formData.initialRothBalance),
    initial_trad_balance: parseFloat(formData.initialTradBalance),
    initial_brokerage_balance: parseFloat(formData.initialBrokerageBalance),
    yearly_roth_401k: parseFloat(formData.yearlyRoth401k),
    yearly_trad_401k: parseFloat(formData.yearlyTrad401k),
    yearly_trad_401k_company_match: parseFloat(formData.yearlyTrad401kCompanyMatch),
    yearly_after_tax_401k: parseFloat(formData.yearlyAfterTax401k),
    yearly_roth_ira: parseFloat(formData.yearlyRothIRA),
    yearly_trad_ira: parseFloat(formData.yearlyTradIRA),
    yearly_brokerage: parseFloat(formData.yearlyBrokerage),
    current_age: parseInt(formData.currentAge),
    contributions_end_age: parseInt(formData.contributionsEndAge),
    withdrawal_start_age: parseInt(formData.withdrawalStartAge),
    income: parseFloat(formData.income),
  }] : [{
    initial_roth_balance: parseFloat(formData.initialRothBalancePerson1),
    initial_trad_balance: parseFloat(formData.initialTradBalancePerson1),
    initial_brokerage_balance: parseFloat(formData.initialBrokerageBalancePerson1),
    yearly_roth_401k: parseFloat(formData.yearlyRoth401kPerson1),
    yearly_trad_401k: parseFloat(formData.yearlyTrad401kPerson1),
    yearly_trad_401k_company_match: parseFloat(formData.yearlyTrad401kCompanyMatchPerson1),
    yearly_after_tax_401k: parseFloat(formData.yearlyAfterTax401kPerson1),
    yearly_roth_ira: parseFloat(formData.yearlyRothIRAPerson1),
    yearly_trad_ira: parseFloat(formData.yearlyTradIRAPerson1),
    yearly_brokerage: parseFloat(formData.yearlyBrokeragePerson1),
    current_age: parseInt(formData.currentAgePerson1),
    contributions_end_age: parseInt(formData.contributionsEndAgePerson1),
    withdrawal_start_age: parseInt(formData.withdrawalStartAgePerson1),
    income: parseFloat(formData.incomePerson1),
  }, {
    initial_roth_balance: parseFloat(formData.initialRothBalancePerson2),
    initial_trad_balance: parseFloat(formData.initialTradBalancePerson2),
    initial_brokerage_balance: parseFloat(formData.initialBrokerageBalancePerson2),
    yearly_roth_401k: parseFloat(formData.yearlyRoth401kPerson2),
    yearly_trad_401k: parseFloat(formData.yearlyTrad401kPerson2),
    yearly_trad_401k_company_match: parseFloat(formData.yearlyTrad401kCompanyMatchPerson2),
    yearly_after_tax_401k: parseFloat(formData.yearlyAfterTax401kPerson2),
    yearly_roth_ira: parseFloat(formData.yearlyRothIRAPerson2),
    yearly_trad_ira: parseFloat(formData.yearlyTradIRAPerson2),
    yearly_brokerage: parseFloat(formData.yearlyBrokeragePerson2),
    current_age: parseInt(formData.currentAgePerson2),
    contributions_end_age: parseInt(formData.contributionsEndAgePerson2),
    withdrawal_start_age: parseInt(formData.withdrawalStartAgePerson2),
    income: parseFloat(formData.incomePerson2),
  }]


  const payload: AfterTaxRetirementIncomeOptimizerRequestPayload =  {
    adjust_yearly_for_inflation: adjustYearlyForInflationToggle,
    filing_status:               FilingStatusConvert.toFilingStatus(JSON.stringify(formData.filingStatus)),
    inflation:                   parseFloat(formData.inflationRate) / 100,
    interest:                    parseFloat(formData.averageAnnualReturn) / 100,
    retirement_financial_inputs: retirementFinancialInputs,
    state:                       StateConvert.toState (JSON.stringify(formData.state)),
    tax_year:                    parseInt(formData.taxYear),
    withdrawal_rate:             parseFloat(formData.withdrawalRate) / 100,
  } 

  // Return the full URL
  return payload
}


  const makeAPIRequest = () => {
    // Load in all form data into query parameters
    const url: string = makeAfterTaxIncomeOptimizerUrl()

    const payload: AfterTaxRetirementIncomeOptimizerRequestPayload = makeAfterTaxIncomeOptimizerPayload(formData)
  
    // Set up fetch options for POST request
    const fetchOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload)
    } ;  
  
    fetchData(url, fetchOptions)
        .then(data => {
          // Process and set the data for the chart
          setChartData(processChartData(data));
  
          // Set state to hide form and show loading or chart
          setShowChart(true);
        })
        .catch(error => {
          console.error('Error:', error);
          // Prompt user about error
  
          handleClick();
        });
    };

  const processChartData = (data: AfterTaxRetirementIncomeOptimizerAPIResponse): number[][] => {
    // Process the data to a format suitable for your chart
    
    // Extract data for the graph
    // Extract the relevant labels from the API response
    const chartData = [
      [
        data.current.income_roth,
        data.current.income_trad,
        data.current.income_brokerage,
        data.current.income_post_tax,
      ],
      [
        data.optimized.income_roth,
        data.optimized.income_trad,
        data.optimized.income_brokerage,
        data.optimized.income_post_tax,
      ]
    ];

  return chartData
  };

  const handleCalculate = useCallback(() => {
    try {
      makeAPIRequest();
    } catch (error) {
      console.error('Error:', error);
      setSnackbarMessage(error instanceof Error ? error.message : 'An unknown error occurred');
    }
  }, [makeAPIRequest, setSnackbarMessage]);

  if (showChart && chartData) {
    return (
      <div ref={chartRef} className="w-full h-screen flex flex-col pt-16 pb-4 px-1 sm:px-2 lg:px-3">
        <div className="flex-grow flex flex-col" style={{ maxHeight: 'calc(100vh - 150px)' }}>
          <div className="flex-grow">
            <AfterTaxIncomeChart labels={labels} chartData={chartData} />
          </div>
          <Button 
            className="w-full mt-4 mb-4 bg-blue-600 hover:bg-blue-700 text-white" 
            onClick={() => setShowChart(false)}
          >
            Edit Assumptions
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="after-tax-retirement-income-optimizer w-full px-1 sm:px-2 lg:px-3 h-full flex flex-col">
      <Card className="w-full flex-grow overflow-hidden flex flex-col mt-16 sm:mt-20 md:mt-24 mb-4">
        <CardHeader>
          <h2 className={cn("text-2xl font-bold")}>{selectedPage}</h2>
        </CardHeader>
        <CardContent className="flex-grow overflow-y-auto">
          {renderInputs()}
        </CardContent>
      </Card>
      {showSnackbar && (
        <div className={cn("fixed bottom-4 left-4 right-4 bg-red-500 text-white p-4 rounded-md")}>
          {snackbarMessage}
        </div>
      )}
    </div>
  );
};

export default AfterTaxRetirementIncomeOptimizerPage;
