import React, { useEffect, useRef } from 'react';
import { Card, CardContent, CardHeader } from '../../components/ui/card';
import { Input } from '../../components/ui/input';
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from '../../components/ui/select';
import { Button } from '../../components/ui/button';
import { Switch } from '../../components/ui/switch';
import { Label } from '../../components/ui/label';
import { cn } from "@/lib/utils";
import IncomeTaxChart from '../../charts/IncomeTaxChart';
import { IncomeTaxCalculatorAPIResponse } from '../../Types/Autogenerated/IncomeTaxCalculatorApiResponse';
import { IncomeTaxCalculatorRequestPayload } from '../../Types/Autogenerated/IncomeTaxCalculatorRequestPayload';
import { IncomesAdjustmentsAndDeductions } from '../../Types/Autogenerated/IncomesAdjustmentsAndDeductions';
import { Convert as FilingStatusConvert } from '../../Types/Autogenerated/FilingStatus';
import { Convert as StateConvert } from '../../Types/Autogenerated/State';
import { FormData } from '../../App';
import { CurrencyInputOnChangeValues } from 'react-currency-input-field';
import { taxYearItems, stateItems, filingStatuses } from '../../Utils/Constants';
import { scrollToChart } from '../../Utils/chartUtils';

interface IncomeTaxCalculatorPageProps {
  formData: FormData;
  selectedPage: string;
  personsToggle: boolean;
  showSnackbar: boolean;
  snackbarMessage: string;
  showChart: boolean;
  chartData: number[][] | null;
  handleClose: (event: React.SyntheticEvent | Event, reason?: string) => void;
  setChartData: (value: React.SetStateAction<number[][] | null>) => void;
  setShowChart: (value: React.SetStateAction<boolean>) => void;
  setSnackbarMessage: (value: React.SetStateAction<string>) => void;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onValueChange: (value: string | undefined, name?: string, values?: CurrencyInputOnChangeValues) => void;
  handleToggleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSelectChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const IncomeTaxCalculatorPage: React.FC<IncomeTaxCalculatorPageProps> = ({
  formData,
  selectedPage,
  personsToggle,
  showSnackbar,
  snackbarMessage,
  showChart,
  chartData,
  handleClose,
  setChartData,
  setShowChart,
  setSnackbarMessage,
  handleInputChange,
  onValueChange,
  handleToggleChange,
  handleSelectChange,
}) => {
  const formatNumber = (num: number | string) => {
    if (typeof num === 'string') {
      num = parseFloat(num.replace(/,/g, ''));
    }
    return isNaN(num) ? '' : num.toLocaleString('en-US');
  };

  const handleIncomeChange = (value: string, name: string) => {
    const numericValue = value ? parseFloat(value.replace(/,/g, '')) : 0;
    onValueChange(numericValue.toString(), name);
  };

  const makeIncomeTaxCalculatorUrl = () => {
    return window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
      ? 'http://127.0.0.1:5000/TakehomePayCalculator'
      : 'https://ykkgchfopnattrgul3quvlzbvm0qpmoc.lambda-url.us-west-2.on.aws/';
  };

  const makeAPIRequest = async () => {
    const url = makeIncomeTaxCalculatorUrl();

    const incomes_adjustments_and_deductions: IncomesAdjustmentsAndDeductions[] = !personsToggle
      ? [{
          salaries_and_wages: Number(formData.income),
        }]
      : [{
          salaries_and_wages: Number(formData.incomePerson1),
        }, {
          salaries_and_wages: Number(formData.incomePerson2),
        }];

    const payload: IncomeTaxCalculatorRequestPayload = {
      tax_year: parseInt(formData.taxYear, 10),
      state: StateConvert.toState(JSON.stringify(formData.state)),
      filing_status: FilingStatusConvert.toFilingStatus(JSON.stringify(formData.filingStatus)),
      incomes_adjustments_and_deductions: incomes_adjustments_and_deductions,
      state_data: { exemptions: 0 }
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Unknown error occurred');
      }

      const data: IncomeTaxCalculatorAPIResponse = await response.json();
      setChartData(processChartData(data));
      setShowChart(true);
    } catch (error) {
      console.error('Error:', error);
      setSnackbarMessage(error instanceof Error ? error.message : 'An unknown error occurred');
    }
  };

  const processChartData = (data: IncomeTaxCalculatorAPIResponse): number[][] => {
    return [[
      data.medicare.income_tax_owed.reduce((a, b) => a + b, 0),
      data.social_security.income_tax_owed.reduce((a, b) => a + b, 0),
      data.state.income_tax_owed.reduce((a, b) => a + b, 0),
      data.federal.income_tax_owed.reduce((a, b) => a + b, 0),
      data.total_tax_owed,
    ]];
  };

  const labels: string[] = [
    "Medicare",
    "Social Security",
    "State",
    "Federal",
    "Total Tax",
  ];

  const sortedTaxYears = Object.entries(taxYearItems)
    .sort((a, b) => parseInt(b[1]) - parseInt(a[1]));

  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (showChart && chartData) {
      scrollToChart(chartRef);
    }
  }, [showChart, chartData]);

  if (showChart && chartData) {
    return (
      <div ref={chartRef} className="w-full h-screen flex flex-col pt-16 pb-4 px-1 sm:px-2 lg:px-3">
        <div className="flex-grow flex flex-col" style={{ maxHeight: 'calc(100vh - 150px)' }}>
          <div className="flex-grow">
            <IncomeTaxChart labels={labels} chartData={chartData} />
          </div>
          <Button 
            className="w-full mt-4 bg-blue-600 hover:bg-blue-700 text-white" 
            onClick={() => setShowChart(false)}
          >
            Edit Assumptions
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="income-tax-calculator w-full px-1 sm:px-2 lg:px-3">
      <Card className="w-full">
        <CardHeader>
          <h2 className={cn("text-2xl font-bold")}>{selectedPage}</h2>
        </CardHeader>
        <CardContent>
          <div className={cn("space-y-4")}>
            <div className={cn("flex items-center justify-between")}>
              <Label htmlFor="personsToggle" className="text-left w-full">Two Incomes</Label>
              <Switch
                checked={personsToggle}
                onCheckedChange={(checked) => handleToggleChange({
                  target: { name: 'personsToggle', checked, id: 'persons' }
                } as React.ChangeEvent<HTMLInputElement>)}
              />
            </div>
            
            <div className={cn("grid grid-cols-2 gap-4")}>
              <div>
                <Label htmlFor={personsToggle ? "incomePerson1" : "income"} className="text-left w-full">
                  {personsToggle ? "Income 1" : "Income"}
                </Label>
                <div className={cn("relative")}>
                  <span className={cn("absolute inset-y-0 left-0 flex items-center pl-3 text-gray-500 text-base md:text-sm")}>$</span>
                  <Input
                    id={personsToggle ? "incomePerson1" : "income"}
                    name={personsToggle ? "incomePerson1" : "income"}
                    type="text"
                    value={formatNumber(personsToggle ? formData.incomePerson1 : formData.income)}
                    onChange={(e) => handleIncomeChange(e.target.value, personsToggle ? 'incomePerson1' : 'income')}
                    className={cn("pl-6")}
                  />
                </div>
              </div>

              {personsToggle && (
                <div>
                  <Label htmlFor="incomePerson2" className="text-left w-full">Income 2</Label>
                  <div className={cn("relative")}>
                    <span className={cn("absolute inset-y-0 left-0 flex items-center pl-3 text-gray-500 text-base md:text-sm")}>$</span>
                    <Input
                      id="incomePerson2"
                      name="incomePerson2"
                      type="text"
                      value={formatNumber(formData.incomePerson2)}
                      onChange={(e) => handleIncomeChange(e.target.value, 'incomePerson2')}
                      className={cn("pl-6")}
                    />
                  </div>
                </div>
              )}
            </div>
            
            <div className={cn("grid grid-cols-2 gap-4")}>
              <div>
                <Label htmlFor="taxYear" className="text-left w-full">Tax Year</Label>
                <Select 
                  value={formData.taxYear} 
                  onValueChange={(value) => handleSelectChange({ target: { name: 'taxYear', value } } as React.ChangeEvent<HTMLSelectElement>)}
                >
                  <SelectTrigger id="taxYear">
                    <SelectValue placeholder="Select year" />
                  </SelectTrigger>
                  <SelectContent>
                    {sortedTaxYears.map(([key, value]) => (
                      <SelectItem key={value} value={value}>{key}</SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              <div>
                <Label htmlFor="state" className="text-left w-full">State</Label>
                <Select 
                  value={formData.state} 
                  onValueChange={(value) => handleSelectChange({ target: { name: 'state', value } } as React.ChangeEvent<HTMLSelectElement>)}
                >
                  <SelectTrigger id="state">
                    <SelectValue placeholder="Select state" />
                  </SelectTrigger>
                  <SelectContent>
                    {Object.entries(stateItems).map(([key, value]) => (
                      <SelectItem key={value} value={value}>{key}</SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            </div>
            
            <div>
              <Label htmlFor="filingStatus" className="text-left w-full">Filing Status</Label>
              <Select 
                value={formData.filingStatus} 
                onValueChange={(value) => handleSelectChange({ target: { name: 'filingStatus', value } } as React.ChangeEvent<HTMLSelectElement>)}
              >
                <SelectTrigger id="filingStatus">
                  <SelectValue placeholder="Select filing status" />
                </SelectTrigger>
                <SelectContent>
                  {Object.entries(filingStatuses).map(([key, value]) => (
                    <SelectItem key={key} value={key}>{value}</SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            
            <Button className={cn("w-full")} onClick={makeAPIRequest}>Calculate Income Tax</Button>
          </div>
        </CardContent>
      </Card>
      {showSnackbar && (
        <div className={cn("fixed bottom-4 left-4 right-4 bg-red-500 text-white p-4 rounded-md")}>
          {snackbarMessage}
        </div>
      )}
    </div>
  );
};

export default IncomeTaxCalculatorPage;
