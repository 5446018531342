import React, { useRef, useEffect } from 'react';
import Chart from 'chart.js/auto';
import './Chart.css';
import { FormData } from '../App';

interface ChartProps {
    labels: string[];
    chartData: number[][];
}

function formatCurrency(value: string | number) {
    const number = Number(value);
    return number.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,  // No decimal places
        maximumFractionDigits: 0,  // No decimal places
    });
}

const AfterTaxIncomeChart: React.FC<ChartProps> = ({labels, chartData}) => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const chartInstance = useRef<Chart | null>(null);

  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext('2d');
      if (ctx) {
        if (chartInstance.current) {
          chartInstance.current.destroy();
        }
        chartInstance.current = new Chart(ctx, {
          type: 'bar',
          data: {
            labels: labels,
            datasets: [{
              label: 'Current',
              data: chartData[0],
              backgroundColor: [
                'rgba(54, 162, 235, 0.2)', 
                'rgba(54, 162, 235, 0.2)', 
                'rgba(54, 162, 235, 0.2)', 
                'rgba(54, 162, 235, 0.6)',
              ],
              borderColor: [
                'rgba(54, 162, 235, 1)', 
                'rgba(54, 162, 235, 1)', 
                'rgba(54, 162, 235, 1)', 
                'rgba(54, 162, 235, 1)',
              ],
              borderWidth: 1,
            },
            {
              label: 'Tax Optimized',
              data: chartData[1],
              backgroundColor: [
                'rgba(75, 192, 192, 0.2)', 
                'rgba(75, 192, 192, 0.2)', 
                'rgba(75, 192, 192, 0.2)', 
                'rgba(75, 192, 192, 0.6)',
              ],
              borderColor: [
                'rgba(75, 192, 192, 1)', 
                'rgba(75, 192, 192, 1)', 
                'rgba(75, 192, 192, 1)', 
                'rgba(75, 192, 192, 1)',
              ],
              borderWidth: 1,
            }]
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              y: {
                beginAtZero: true,
                ticks: {
                  callback: formatCurrency
                }
              }
            },
            plugins: {
              tooltip: {
                callbacks: {
                  label: function(context) {
                    let label = context.dataset.label || '';
                    if (label) {
                      label += ': ';
                    }
                    if (context.parsed.y !== null) {
                      label += formatCurrency(context.parsed.y);
                    }
                    return label;
                  }
                }
              }
            }
          }
        });
      }
    }
  }, [chartData, labels]);

  return (
    <div className="w-full h-full">
      <canvas ref={chartRef} className="w-full h-full"></canvas>
    </div>
  );
};

export default AfterTaxIncomeChart;
