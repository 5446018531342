import React, { useEffect, useRef, useCallback } from 'react';
import { Card, CardContent, CardHeader } from '../../components/ui/card';
import { Input } from '../../components/ui/input';
import { Button } from '../../components/ui/button';
import { Switch } from '../../components/ui/switch';
import { Label } from '../../components/ui/label';
import { cn } from "@/lib/utils";
import SimplifiedRetirementIncomeChart from '../../charts/SimplifiedRetirementIncomeChart';
import { SimplifiedRetirementFinancialInputs } from '../../Types/Autogenerated/SimplifiedRetirementFinancialInputs';
import { FormData } from '../../App';
import { CurrencyInputOnChangeValues } from 'react-currency-input-field';
import { SimplifiedRetirementCalculator } from '../../Utils/SimplifiedRetirementCalculator';
import { scrollToChart } from '../../Utils/chartUtils';


interface RetirementIncomeCalculatorPageProps {
  formData: FormData;
  selectedPage: string;
  personsToggle: boolean;
  adjustYearlyForInflationToggle: boolean;
  showSnackbar: boolean;
  snackbarMessage: string;
  showChart: boolean;
  chartData: number[][] | null;
  handleClose: (event: React.SyntheticEvent | Event, reason?: string) => void;
  setChartData: (value: React.SetStateAction<number[][] | null>) => void;
  setShowChart: (value: React.SetStateAction<boolean>) => void;
  setSnackbarMessage: (value: React.SetStateAction<string>) => void;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onValueChange: (value: string | undefined, name?: string, values?: CurrencyInputOnChangeValues) => void;
  handleToggleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const RetirementIncomeCalculatorPage: React.FC<RetirementIncomeCalculatorPageProps> = ({
  formData,
  selectedPage,
  personsToggle,
  adjustYearlyForInflationToggle,
  showSnackbar,
  snackbarMessage,
  showChart,
  chartData,
  handleClose,
  setChartData,
  setShowChart,
  setSnackbarMessage,
  handleInputChange,
  onValueChange,
  handleToggleChange,
}) => {
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (showChart && chartData) {
      scrollToChart(chartRef);
    }
  }, [showChart, chartData]);

  const formatNumber = (num: number | string) => {
    if (typeof num === 'string') {
      num = parseFloat(num.replace(/,/g, ''));
    }
    return isNaN(num) ? '' : num.toLocaleString('en-US');
  };

  const handleFinancialInputChange = (value: string, name: string) => {
    const numericValue = value ? parseFloat(value.replace(/,/g, '')) : 0;
    onValueChange(numericValue.toString(), name);
  };

  const calculateRetirementIncome = () => {
    const retirementFinancialInputs: SimplifiedRetirementFinancialInputs[] = personsToggle
      ? [
          {
            initial_investments_balance: parseFloat(formData.initialInvestmentsBalancePerson1),
            yearly_investments_contribution: parseFloat(formData.yearlyInvestmentsContributionPerson1),
            contributions_end_age: parseInt(formData.contributionsEndAgePerson1, 10),
            withdrawal_start_age: parseInt(formData.withdrawalStartAgePerson1, 10),
            current_age: parseInt(formData.currentAgePerson1, 10),
          },
          {
            initial_investments_balance: parseFloat(formData.initialInvestmentsBalancePerson2),
            yearly_investments_contribution: parseFloat(formData.yearlyInvestmentsContributionPerson2),
            contributions_end_age: parseInt(formData.contributionsEndAgePerson2, 10),
            withdrawal_start_age: parseInt(formData.withdrawalStartAgePerson2, 10),
            current_age: parseInt(formData.currentAgePerson2, 10),
          }
        ]
      : [
          {
            initial_investments_balance: parseFloat(formData.initialInvestmentsBalance),
            yearly_investments_contribution: parseFloat(formData.yearlyInvestmentsContribution),
            contributions_end_age: parseInt(formData.contributionsEndAge, 10),
            withdrawal_start_age: parseInt(formData.withdrawalStartAge, 10),
            current_age: parseInt(formData.currentAge, 10),
          }
        ];

    const personsData: number[][] = [];

    retirementFinancialInputs.forEach((input, index) => {
      const calculator = new SimplifiedRetirementCalculator(
        input,
        adjustYearlyForInflationToggle,
        parseFloat(formData.averageAnnualReturn) / 100,
        parseFloat(formData.inflationRate) / 100,
        parseFloat(formData.withdrawalRate) / 100,
      );
      personsData.push(calculator.compute_retirement_savings_timeseries("BROKERAGE"));
    });

    setChartData(personsData);
    setShowChart(true);
  };

  const labels: string[] = ["Annual Income*"];

  const renderPercentageInput = (label: string, name: string) => (
    <div>
      <Label htmlFor={name} className="text-left w-full">{label}</Label>
      <div className={cn("relative")}>
        <Input
          id={name}
          name={name}
          type="number"
          value={formData[name as keyof FormData]?.toString() || ''}
          onChange={handleInputChange}
          className={cn("pr-8")}
        />
        <span className={cn("absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500 text-base md:text-sm")}>%</span>
      </div>
    </div>
  );

  const handleCalculate = useCallback(() => {
    try {
      calculateRetirementIncome();
    } catch (error) {
      console.error('Error:', error);
      setSnackbarMessage(error instanceof Error ? error.message : 'An unknown error occurred');
    }
  }, [calculateRetirementIncome, setSnackbarMessage]);

  if (showChart && chartData) {
    return (
      <div ref={chartRef} className="w-full h-screen flex flex-col pt-16 pb-4 px-1 sm:px-2 lg:px-3">
        <div className="flex-grow flex flex-col" style={{ maxHeight: 'calc(100vh - 150px)' }}>
          <div className="flex-grow">
            <SimplifiedRetirementIncomeChart 
              timeSeriesData={chartData} 
              withdrawalRate={parseFloat(formData.withdrawalRate)}
              contributionsEndAge={formData.contributionsEndAge}
              contributionsEndAgePerson1={formData.contributionsEndAgePerson1}
              contributionsEndAgePerson2={formData.contributionsEndAgePerson2}
              withdrawalStartAge={formData.withdrawalStartAge}
              withdrawalStartAgePerson1={formData.withdrawalStartAgePerson1}
              withdrawalStartAgePerson2={formData.withdrawalStartAgePerson2}
              currentAge={formData.currentAge}
              currentAgePerson1={formData.currentAgePerson1}
              currentAgePerson2={formData.currentAgePerson2}
              personsToggle={personsToggle} 
            />
          </div>
          <Button 
            className="w-full mt-4 bg-blue-600 hover:bg-blue-700 text-white" 
            onClick={() => setShowChart(false)}
          >
            Edit Assumptions
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="retirement-income-calculator w-full px-1 sm:px-2 lg:px-3 h-full flex flex-col">
      <Card className="w-full flex-grow overflow-hidden flex flex-col mt-16 sm:mt-20 md:mt-24 mb-4">
        <CardHeader>
          <h2 className={cn("text-2xl font-bold")}>{selectedPage}</h2>
        </CardHeader>
        <CardContent className="flex-grow overflow-y-auto">
          <div className={cn("space-y-4 pb-4")}>
            <div className={cn("flex items-center justify-between")}>
              <Label htmlFor="personsToggle" className="text-left w-full">Two Incomes</Label>
              <Switch
                checked={personsToggle}
                onCheckedChange={(checked) => handleToggleChange({
                  target: { name: 'personsToggle', checked, id: 'persons' }
                } as React.ChangeEvent<HTMLInputElement>)}
              />
            </div>

            <div className={cn("grid grid-cols-2 gap-4")}>
              <div className={cn("space-y-4")}>
                <div>
                  <Label htmlFor={personsToggle ? "initialInvestmentsBalancePerson1" : "initialInvestmentsBalance"} className="text-left w-full">
                    {personsToggle ? "Current Investments 1" : "Current Investments"}
                  </Label>
                  <div className={cn("relative")}>
                    <span className={cn("absolute inset-y-0 left-0 flex items-center pl-3 text-gray-500 text-base md:text-sm")}>$</span>
                    <Input
                      id={personsToggle ? "initialInvestmentsBalancePerson1" : "initialInvestmentsBalance"}
                      name={personsToggle ? "initialInvestmentsBalancePerson1" : "initialInvestmentsBalance"}
                      type="text"
                      value={formatNumber(personsToggle ? formData.initialInvestmentsBalancePerson1 : formData.initialInvestmentsBalance)}
                      onChange={(e) => handleFinancialInputChange(e.target.value, personsToggle ? 'initialInvestmentsBalancePerson1' : 'initialInvestmentsBalance')}
                      className={cn("pl-6")}
                    />
                  </div>
                </div>
                <div>
                  <Label htmlFor={personsToggle ? "yearlyInvestmentsContributionPerson1" : "yearlyInvestmentsContribution"} className="text-left w-full">
                    {personsToggle ? "Annual Savings 1" : "Annual Savings"}
                  </Label>
                  <div className={cn("relative")}>
                    <span className={cn("absolute inset-y-0 left-0 flex items-center pl-3 text-gray-500 text-base md:text-sm")}>$</span>
                    <Input
                      id={personsToggle ? "yearlyInvestmentsContributionPerson1" : "yearlyInvestmentsContribution"}
                      name={personsToggle ? "yearlyInvestmentsContributionPerson1" : "yearlyInvestmentsContribution"}
                      type="text"
                      value={formatNumber(personsToggle ? formData.yearlyInvestmentsContributionPerson1 : formData.yearlyInvestmentsContribution)}
                      onChange={(e) => handleFinancialInputChange(e.target.value, personsToggle ? 'yearlyInvestmentsContributionPerson1' : 'yearlyInvestmentsContribution')}
                      className={cn("pl-6")}
                    />
                  </div>
                </div>
                <div>
                  <Label htmlFor={personsToggle ? "currentAgePerson1" : "currentAge"} className="text-left w-full">
                    {personsToggle ? "Current Age 1" : "Current Age"}
                  </Label>
                  <Input
                    id={personsToggle ? "currentAgePerson1" : "currentAge"}
                    name={personsToggle ? "currentAgePerson1" : "currentAge"}
                    type="number"
                    value={personsToggle ? formData.currentAgePerson1 : formData.currentAge}
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <Label htmlFor={personsToggle ? "contributionsEndAgePerson1" : "contributionsEndAge"} className="text-left w-full">
                    {personsToggle ? "Stop Saving Age 1" : "Stop Saving Age"}
                  </Label>
                  <Input
                    id={personsToggle ? "contributionsEndAgePerson1" : "contributionsEndAge"}
                    name={personsToggle ? "contributionsEndAgePerson1" : "contributionsEndAge"}
                    type="number"
                    value={personsToggle ? formData.contributionsEndAgePerson1 : formData.contributionsEndAge}
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <Label htmlFor={personsToggle ? "withdrawalStartAgePerson1" : "withdrawalStartAge"} className="text-left w-full">
                    {personsToggle ? "Retirement Age 1" : "Retirement Age"}
                  </Label>
                  <Input
                    id={personsToggle ? "withdrawalStartAgePerson1" : "withdrawalStartAge"}
                    name={personsToggle ? "withdrawalStartAgePerson1" : "withdrawalStartAge"}
                    type="number"
                    value={personsToggle ? formData.withdrawalStartAgePerson1 : formData.withdrawalStartAge}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              {personsToggle && (
                <div className={cn("space-y-4")}>
                  <div>
                    <Label htmlFor="initialInvestmentsBalancePerson2" className="text-left w-full">Current Investments 2</Label>
                    <div className={cn("relative")}>
                      <span className={cn("absolute inset-y-0 left-0 flex items-center pl-3 text-gray-500 text-base md:text-sm")}>$</span>
                      <Input
                        id="initialInvestmentsBalancePerson2"
                        name="initialInvestmentsBalancePerson2"
                        type="text"
                        value={formatNumber(formData.initialInvestmentsBalancePerson2)}
                        onChange={(e) => handleFinancialInputChange(e.target.value, 'initialInvestmentsBalancePerson2')}
                        className={cn("pl-6")}
                      />
                    </div>
                  </div>
                  <div>
                    <Label htmlFor="yearlyInvestmentsContributionPerson2" className="text-left w-full">Annual Savings 2</Label>
                    <div className={cn("relative")}>
                      <span className={cn("absolute inset-y-0 left-0 flex items-center pl-3 text-gray-500 text-base md:text-sm")}>$</span>
                      <Input
                        id="yearlyInvestmentsContributionPerson2"
                        name="yearlyInvestmentsContributionPerson2"
                        type="text"
                        value={formatNumber(formData.yearlyInvestmentsContributionPerson2)}
                        onChange={(e) => handleFinancialInputChange(e.target.value, 'yearlyInvestmentsContributionPerson2')}
                        className={cn("pl-6")}
                      />
                    </div>
                  </div>
                  <div>
                    <Label htmlFor="currentAgePerson2" className="text-left w-full">Current Age 2</Label>
                    <Input
                      id="currentAgePerson2"
                      name="currentAgePerson2"
                      type="number"
                      value={formData.currentAgePerson2}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div>
                    <Label htmlFor="contributionsEndAgePerson2" className="text-left w-full">Stop Saving Age 2</Label>
                    <Input
                      id="contributionsEndAgePerson2"
                      name="contributionsEndAgePerson2"
                      type="number"
                      value={formData.contributionsEndAgePerson2}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div>
                    <Label htmlFor="withdrawalStartAgePerson2" className="text-left w-full">Retirement Age 2</Label>
                    <Input
                      id="withdrawalStartAgePerson2"
                      name="withdrawalStartAgePerson2"
                      type="number"
                      value={formData.withdrawalStartAgePerson2}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              )}
            </div>

            <div className={cn("flex items-center justify-between")}>
              <Label htmlFor="adjustYearlyForInflationToggle" className="text-left w-full">Grow Contributions With Inflation</Label>
              <Switch
                checked={adjustYearlyForInflationToggle}
                onCheckedChange={(checked) => handleToggleChange({
                  target: { name: 'adjustYearlyForInflationToggle', checked, id: 'adjustYearlyForInflationToggle' }
                } as React.ChangeEvent<HTMLInputElement>)}
              />
            </div>

            <div className={cn("grid grid-cols-2 gap-4")}>
              {renderPercentageInput("Withdrawal Rate", "withdrawalRate")}
              {renderPercentageInput("Annual Return", "averageAnnualReturn")}
            </div>

            {renderPercentageInput("Inflation Rate", "inflationRate")}

            <Button 
              className={cn("w-full")} 
              onClick={handleCalculate}
              onTouchStart={(e) => {
                e.preventDefault();
                handleCalculate();
              }}
            >
              Calculate Retirement Income
            </Button>
          </div>
        </CardContent>
      </Card>
      {showSnackbar && (
        <div className={cn("fixed bottom-4 left-4 right-4 bg-red-500 text-white p-4 rounded-md")}>
          {snackbarMessage}
        </div>
      )}
    </div>
  );
};

export default RetirementIncomeCalculatorPage;
