import React, { useEffect, useState } from 'react';
import type { CurrencyInputOnChangeValues } from 'react-currency-input-field';
import './App.css';
import ResponsiveDrawer from './ResponsiveDrawer';
import AfterTaxRetirementIncomeOptimizerPage from './Pages/Optimizers/AfterTaxRetirementIncomePage';
import IncomeTaxCalculatorPage from './Pages/Calculators/IncomeTaxPage';
import RetirementIncomeCalculatorPage from './Pages/Calculators/SimplifiedRetirementIncomePage';
import TakehomePayCalculatorPage from './Pages/Calculators/TakehomePayCalculatorPage';
import './global.css';

export interface FormData {
  // Shared Fields
  personsToggle: boolean;
  adjustYearlyForInflationToggle: boolean;
  averageAnnualReturn: string;
  inflationRate: string;
  withdrawalRate: string;
  taxYear: string;
  state: string;
  filingStatus: string;
  previousFilingStatus: string;
  // One-Person Fields
  initialInvestmentsBalance: string,
  yearlyInvestmentsContribution: string,
  initialRothBalance: string;
  initialTradBalance: string;
  initialBrokerageBalance: string;
  yearlyRoth401k: string;
  yearlyTrad401k: string;
  yearlyTrad401kCompanyMatch: string;
  yearlyAfterTax401k: string;
  yearlyRothIRA: string;
  yearlyTradIRA: string;
  yearlyBrokerage: string;
  income: string;
  charitableGivingRate: string;
  currentAge: string;
  contributionsEndAge: string;
  withdrawalStartAge: string;
  // Two-Person Fields
  initialInvestmentsBalancePerson1: string,
  initialInvestmentsBalancePerson2: string,
  yearlyInvestmentsContributionPerson1: string,
  yearlyInvestmentsContributionPerson2: string,
  initialRothBalancePerson1: string;
  initialRothBalancePerson2: string;
  initialTradBalancePerson1: string;
  initialTradBalancePerson2: string;
  initialBrokerageBalancePerson1: string;
  initialBrokerageBalancePerson2: string;
  yearlyTrad401kPerson1: string;
  yearlyTrad401kPerson2: string;
  yearlyRoth401kPerson1: string;
  yearlyRoth401kPerson2: string;
  yearlyTrad401kCompanyMatchPerson1: string;
  yearlyTrad401kCompanyMatchPerson2: string;
  yearlyAfterTax401kPerson1: string;
  yearlyAfterTax401kPerson2: string;
  yearlyRothIRAPerson1: string;
  yearlyRothIRAPerson2: string;
  yearlyTradIRAPerson1: string;
  yearlyTradIRAPerson2: string;
  yearlyBrokeragePerson1: string;
  yearlyBrokeragePerson2: string;
  incomePerson1: string;
  incomePerson2: string;
  charitableGivingRatePerson1: string;
  charitableGivingRatePerson2: string;
  currentAgePerson1: string;
  currentAgePerson2: string;
  contributionsEndAgePerson1: string;
  contributionsEndAgePerson2: string;
  withdrawalStartAgePerson1: string;
  withdrawalStartAgePerson2: string;
}
const defaultFormData: FormData = {
  // Shared Fields
  personsToggle: false,
  adjustYearlyForInflationToggle: false,
  averageAnnualReturn: '7.2',
  inflationRate: '2.0',
  withdrawalRate: '3.5',
  taxYear: '2024',
  state: 'Georgia',
  filingStatus: 'Married_Filing_Jointly',
  previousFilingStatus: 'Married_Filing_Jointly',
  // One-Person Fields
  initialInvestmentsBalance: '100000',
  yearlyInvestmentsContribution: '22500',
  initialRothBalance: '20000',
  initialTradBalance: '100000',
  initialBrokerageBalance: '30000',
  yearlyTrad401k: '22500',
  yearlyRoth401k: '0',
  yearlyTrad401kCompanyMatch: '4000',
  yearlyAfterTax401k: '0',
  yearlyRothIRA: '6000',
  yearlyTradIRA: '0',
  yearlyBrokerage: '5000',
  income: '150000',
  charitableGivingRate: '10',
  currentAge: '29',
  contributionsEndAge: '65',
  withdrawalStartAge: '65',
  // Two-Person Fields
  initialInvestmentsBalancePerson1: '100000',
  initialInvestmentsBalancePerson2: '25000',
  yearlyInvestmentsContributionPerson1: '22500',
  yearlyInvestmentsContributionPerson2: '11000',
  initialRothBalancePerson1: '20000',
  initialRothBalancePerson2: '0',
  initialTradBalancePerson1: '100000',
  initialTradBalancePerson2: '25000',
  initialBrokerageBalancePerson1: '30000',
  initialBrokerageBalancePerson2: '10000',
  yearlyTrad401kPerson1: '22500',
  yearlyTrad401kPerson2: '11000',
  yearlyRoth401kPerson1: '0',
  yearlyRoth401kPerson2: '0',
  yearlyTrad401kCompanyMatchPerson1: '5000',
  yearlyTrad401kCompanyMatchPerson2: '2000',
  yearlyAfterTax401kPerson1: '0',
  yearlyAfterTax401kPerson2: '0',
  yearlyRothIRAPerson1: '6000',
  yearlyRothIRAPerson2: '0',
  yearlyTradIRAPerson1: '0',
  yearlyTradIRAPerson2: '3000',
  yearlyBrokeragePerson1: '5000',
  yearlyBrokeragePerson2: '25000',
  incomePerson1: '150000',
  incomePerson2: '80000',
  charitableGivingRatePerson1: '12',
  charitableGivingRatePerson2: '8',
  currentAgePerson1: '29',
  currentAgePerson2: '30',
  contributionsEndAgePerson1: '62',
  contributionsEndAgePerson2: '58',
  withdrawalStartAgePerson1: '62',
  withdrawalStartAgePerson2: '58',
};

const buttonStyle = {
  margin: 32
}

function App() {
  const [showChart, setShowChart] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleClick = () => {
    setShowSnackbar(true);
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowSnackbar(false);
  };

  const [afterTaxRetirementOptimizerChartData, setAfterTaxRetirementOptimizerChartData] = useState<number[][] | null>(null);
  const [incomeTaxCalculatorChartData, setIncomeTaxCalculatorChartData] = useState<number[][] | null>(null);

  const [formData, setFormData] = useState(() => {
    // Read initial data from local storage
    const savedFormData = localStorage.getItem('formData');
    // Merge defaults with saved data, favoring saved data
    return savedFormData ? { ...defaultFormData, ...JSON.parse(savedFormData) } : defaultFormData;
  });

  // Effect to update local storage when formData changes
  useEffect(() => {
    localStorage.setItem('formData', JSON.stringify(formData));
  }, [formData]);

  const [personsToggle, setIsToggled] = useState(formData.personsToggle);
  const [adjustYearlyForInflationToggle, setAdjustForInflation] = useState(formData.adjustYearlyForInflationToggle);
  const [selectedPage, setSelectedPage] = React.useState('Take-Home Pay');
  const handleListItemClick = (contentKey: string) => {
    setSelectedPage(contentKey);
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    
    const { name, value } = event.target;
    
    const newFormData = {
      ...formData,
      [name]: value,
    };

    setFormData(newFormData);
  };

  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const toggleId = event.target.id;
    if (toggleId === 'persons') {
      setIsToggled(event.target.checked);
    } else if (toggleId === 'adjustYearlyForInflationToggle') {
      setAdjustForInflation(event.target.checked);
    } else {
      console.error('Unknown toggleId: ', toggleId)
    }
    handleInputChange(event)
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // All currency fields should use onValueChange, so this should only be used for 
    // non-currency fields (ie. checkbox, numberinput, dateinput, etc)
    const { name, type, value, checked } = event.target;

    const isCheckbox = type === 'checkbox';
    
    const newFormData = {
      ...formData,
      [name]: isCheckbox ? checked : value,
    };

    setFormData(newFormData);
};

const onValueChange = (value: string | undefined, name?: string, values?: CurrencyInputOnChangeValues) => {
  console.debug(value, name, values)
  
  const newFormData = {
    ...formData,
    [name!]: value,
  };

  setFormData(newFormData);
};

// Runs when page is refreshed, load form-fields from URL if they are stored there.
// TODO: values are now stored in local storage, so perhaps this should be deleted.
useEffect(() => {
  const searchParams = new URLSearchParams(window.location.search);
  const newFormData: any = { ...formData };

  Object.keys(formData).forEach(key => {
    if (searchParams.has(key)) {
      const value = searchParams.get(key);

      if (value !== null) {
        if (typeof formData[key as keyof FormData] === 'boolean') {
          // Convert the string value to boolean
          newFormData[key as keyof FormData] = value === 'true';
        } else {
          // Assign the value directly for strings
          newFormData[key as keyof FormData] = value;
        }
      }
    }
  });

  setFormData(newFormData as FormData);
}, []);

  // Effect to reset chart state when page changes
  useEffect(() => {
    setShowChart(false);
  }, [selectedPage]);

  // Function to render pages based on the selected page in the navbar
  // TODO: Is there a method to pass the inputs to the page instantiation implicitly? 
  const renderSelectedPage = () => {
    if (selectedPage == 'After-Tax Retirement Income') {
      return <AfterTaxRetirementIncomeOptimizerPage 
        formData={formData}
        selectedPage={selectedPage}
        personsToggle={personsToggle}
        adjustYearlyForInflationToggle={adjustYearlyForInflationToggle}
        showSnackbar={showSnackbar}
        snackbarMessage={snackbarMessage}
        showChart={showChart}
        chartData={afterTaxRetirementOptimizerChartData}
        buttonStyle={buttonStyle}
        handleClose={handleClose}
        setChartData={setAfterTaxRetirementOptimizerChartData}
        setShowChart={setShowChart}
        handleClick={handleClick}
        setSnackbarMessage={setSnackbarMessage}
        handleInputChange={handleInputChange}
        onValueChange={onValueChange}
        handleToggleChange={handleToggleChange}
        handleSelectChange={handleSelectChange}
      />

    } else if (selectedPage == 'Income Tax') {
      return <IncomeTaxCalculatorPage
        formData={formData}
        selectedPage={selectedPage}
        personsToggle={personsToggle}
        showSnackbar={showSnackbar}
        snackbarMessage={snackbarMessage}
        showChart={showChart}
        chartData={incomeTaxCalculatorChartData}
        handleClose={handleClose}
        setChartData={setIncomeTaxCalculatorChartData}
        setShowChart={setShowChart}
        setSnackbarMessage={setSnackbarMessage}
        handleInputChange={handleInputChange}
        onValueChange={onValueChange}
        handleToggleChange={handleToggleChange}
        handleSelectChange={handleSelectChange}
      />
    } else if (selectedPage == 'Retirement Income') {
      return <RetirementIncomeCalculatorPage
        formData={formData}
        selectedPage={selectedPage}
        personsToggle={personsToggle}
        adjustYearlyForInflationToggle={adjustYearlyForInflationToggle}
        showSnackbar={showSnackbar}
        snackbarMessage={snackbarMessage}
        showChart={showChart}
        chartData={afterTaxRetirementOptimizerChartData}
        handleClose={handleClose}
        setChartData={setAfterTaxRetirementOptimizerChartData}
        setShowChart={setShowChart}
        setSnackbarMessage={setSnackbarMessage}
        handleInputChange={handleInputChange}
        onValueChange={onValueChange}
        handleToggleChange={handleToggleChange}
      />
    } else if (selectedPage == 'Take-Home Pay') {
      return <TakehomePayCalculatorPage
        formData={formData}
        selectedPage={selectedPage}
        handleInputChange={handleInputChange}
        onValueChange={onValueChange}
        handleToggleChange={handleToggleChange}
        setSnackbarMessage={setSnackbarMessage}
        showChart={showChart}
        showSnackbar={showSnackbar}
        snackbarMessage={snackbarMessage}
        setShowChart={setShowChart}
        setChartData={setIncomeTaxCalculatorChartData}
        setShowSnackbar={setShowSnackbar}
        chartData={incomeTaxCalculatorChartData}
        personsToggle={personsToggle}
        handleSelectChange={handleSelectChange}
      />
    } else {
      return (<h2 color="red">COMING SOON</h2>);
    }
    
  };

    return (
      // Display form
      <div className="App">
        <div className='logo-container-class'>
          <h1 className='logo-header'> </h1> {/* TOOO - Remove this section (which currenty only creates darkspace when you scroll up hard) */}
        </div>
        <header className="App-header">
           <ResponsiveDrawer onListItemClick={handleListItemClick}>
              {renderSelectedPage()}
           </ResponsiveDrawer>
        </header>
      </div>
    );
}

export default App;
