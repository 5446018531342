import { SimplifiedRetirementFinancialInputs } from '../Types/Autogenerated/SimplifiedRetirementFinancialInputs';
import { FinancialData } from '../Types/Autogenerated/FinancialData';

// Update the FinancialData interface
export interface SimplifiedFinancialData {
  yearly_brokerage: number;
  adjust_yearly_for_inflation: boolean;
  interest: number;
  inflation: number;
  withdrawal_rate: number;
}

class SimplifiedRetirementCalculator {
  private initial_brokerage_balance: number;
  private yearly_brokerage: number;
  private adjust_yearly_for_inflation: boolean;
  private current_age: number;
  private contributions_end_age: number;
  private withdrawal_start_age: number;
  private interest: number;
  private inflation: number;
  private withdrawal_rate: number;
  private years_until_retirement: number;

  constructor(
    retirement_financial_inputs: SimplifiedRetirementFinancialInputs,
    adjust_yearly_for_inflation: boolean,
    interest: number,
    inflation: number,
    withdrawal_rate: number
  ) {
    // Process inputs
    this.initial_brokerage_balance = retirement_financial_inputs.initial_investments_balance;
    this.yearly_brokerage = retirement_financial_inputs.yearly_investments_contribution;
    this.adjust_yearly_for_inflation = adjust_yearly_for_inflation;
    this.current_age = retirement_financial_inputs.current_age;
    this.contributions_end_age = retirement_financial_inputs.contributions_end_age;
    this.withdrawal_start_age = retirement_financial_inputs.withdrawal_start_age;

    this.interest = interest;
    this.inflation = inflation;
    this.withdrawal_rate = withdrawal_rate;

    // Compute retirement
    this.years_until_retirement = this.withdrawal_start_age - this.current_age;
    if (this.years_until_retirement < 0) {
      throw new Error("Withdrawal Start Age cannot be less than Current Age");
    }

    if (this.current_age > this.contributions_end_age) {
      throw new Error("Contributions End Age cannot be less than Current Age");
    }
  }

  compute_total_contributions_per_year(): number {
    return this.yearly_brokerage;
  }

  compute_total_savings_per_year(account: string): number {
    if (account === 'BROKERAGE') {
      return this.yearly_brokerage;
    } else {
      throw new Error(`Invalid account type ${account}`);
    }
  }

  get_initial_balance(account: string): number {
    if (account === 'BROKERAGE') {
      return this.initial_brokerage_balance;
    } else {
      throw new Error(`Invalid account type ${account}`);
    }
  }

  compute_future_value(npv: number, interest: number, years: number): number {
    // interest: 0.04 corresponds to 4% interest
    return npv * ((interest + 1) ** years);
  }

  compute_present_value(nfv: number, interest: number, years: number): number {
    // interest: 0.04 corresponds to 4% interest
    return nfv / ((interest + 1) ** years);
  }

// Computes the inflation-adjusted value of the retirement savings over time
compute_retirement_savings_timeseries(account: string): number[] {
    const yearly_contribution = this.compute_total_savings_per_year(account);
    const initial_balance = this.get_initial_balance(account);

    const total_years = this.withdrawal_start_age - this.current_age;

    // All NFV are at the time are for their current year, and will show the "account balance" for that year
    // We're assuming that no contributions are made in the first year
    const savings_nfv_array: number[] = [initial_balance];

    for (let age = this.current_age; age < Math.max(this.contributions_end_age, this.withdrawal_start_age); age++) {
      let contribution: number;
      if (age < this.contributions_end_age) {
        if (this.adjust_yearly_for_inflation) {
          contribution = this.compute_future_value(yearly_contribution, this.inflation, age - this.current_age);
        } else {
          contribution = yearly_contribution;
        }
      } else {
        contribution = 0;
      }

      // We're assuming that contributions have on average 6 months to grow
      const contribution_nfv = this.compute_future_value(contribution, this.interest, 0.5);

      // We're assuming that the balance has one full year to grow
      const balance_nfv = this.compute_future_value(savings_nfv_array[savings_nfv_array.length - 1], this.interest, 1);

      savings_nfv_array.push(balance_nfv + contribution_nfv);
    }

    // Compute savings_npv_array in "today's dollars" by adjusting for inflation
    const savings_npv_array: number[] = savings_nfv_array.map((nfv, index) => {
      return this.compute_present_value(nfv, this.inflation, index);
    });

    return savings_npv_array;
  }

  print_params(): void {
    console.log(`Calculations based on saving $${this.compute_total_contributions_per_year().toLocaleString()} per year and savings-grow-with-inflation: ${this.adjust_yearly_for_inflation}\n`);
    console.log(`Initial Brokerage Balance: $${this.initial_brokerage_balance.toLocaleString()}`);
    console.log(`Yearly Brokerage Contributions: $${this.yearly_brokerage.toLocaleString()}`);
    console.log(`Adjust Yearly for Inflation: ${this.adjust_yearly_for_inflation}`);
    console.log(`Interest: ${(this.interest * 100).toFixed(3)}%`);
    console.log(`Current Age: ${this.current_age}`);
    console.log(`Contributions End Age: ${this.contributions_end_age}`);
    console.log(`Withdrawal Start Age: ${this.withdrawal_start_age}`);
    console.log(`Inflation: ${(this.inflation * 100).toFixed(3)}%`);
    console.log(`Withdrawal Rate: ${(this.withdrawal_rate * 100).toFixed(3)}%`);
  }

  get_financial_data(): SimplifiedFinancialData {
    return {
      yearly_brokerage: this.yearly_brokerage,
      adjust_yearly_for_inflation: this.adjust_yearly_for_inflation,
      interest: this.interest,
      inflation: this.inflation,
      withdrawal_rate: this.withdrawal_rate
    };
  }

}

export { SimplifiedRetirementCalculator };
export type { SimplifiedRetirementFinancialInputs };
