export const scrollToChart = (chartRef: React.RefObject<HTMLElement>) => {
  // Set the viewport meta tag to prevent zooming
  const viewport = document.querySelector('meta[name="viewport"]');
  if (viewport) {
    viewport.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no');
  }

  // Scroll to the chart
  chartRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
};

export default { scrollToChart };
