import React from 'react';
import { PieChart, Pie, Cell, Sector, ResponsiveContainer } from 'recharts';
import './TakehomePayChart.css';

interface ChartProps {
    labels: string[];
    chartData: number[][];
}

const COLORS: { [key: string]: string } = {
    income: 'rgba(54, 162, 235, 1)',
    giving: 'rgba(75, 192, 150, 1)',
    cost: 'rgba(214, 105, 120, 1)'
};

const renderActiveShape = (props: any) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 10;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
        <g>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text x={ex + (cos >= 0 ? 1 : -1) * -26} y={ey + (sin >= 0 ? 1 : -1) * 20 + (sin >= 0 ? 0 : -1) * 26} textAnchor={textAnchor} fill="#FFFFFF" fontSize={14} fontWeight="bold">{`${payload.name}`}</text>
            <text x={ex + (cos >= 0 ? 1 : -1) * -26} y={ey + (sin >= 0 ? 1 : -1) * 20 + (sin >= 0 ? 0 : -1) * 26} dy={18} textAnchor={textAnchor} fill="#CCCCCC" fontSize={12}>
                {`$${Math.round(value).toLocaleString()} (${(percent * 100).toFixed(1)}%)`}
            </text>
            {(payload.category === 'cost' || payload.category === 'income') && payload.breakdown && (
                <g>
                    {payload.breakdown.map((item: any, index: number) => (
                        <text 
                            key={item.name} 
                            x={ex + (cos >= 0 ? 1 : -1) * -26} 
                            y={ey + (sin >= 0 ? 1 : -1) * 20 + (sin >= 0 ? 0 : -1) * 26} 
                            dy={36 + index * 18} 
                            textAnchor={textAnchor} 
                            fill="#AAAAAA" 
                            fontSize={10}
                        >
                            {`${item.name}: $${Math.round(item.value).toLocaleString()}`}
                        </text>
                    ))}
                </g>
            )}
        </g>
    );
};

const TakehomePayChart: React.FC<ChartProps> = ({ labels, chartData }) => {
    const annualIncome = Math.round(chartData[0][0]);
    const monthlyIncome = Math.round(annualIncome / 12);
    // const weeklyIncome = Math.round(monthlyIncome / 4.33);
    // const biweeklyIncome = Math.round(monthlyIncome / 2);

    const taxTotal = Math.round(chartData[0].slice(2).reduce((a, b) => a + b, 0));
    const taxBreakdown = labels.slice(2).map((label, index) => ({
        name: label,
        value: Math.round(chartData[0][index + 2])
    }));

    const data = [
        { 
            name: labels[0], 
            value: annualIncome, 
            color: COLORS.income, 
            category: 'income',
            breakdown: [
                { name: 'Monthly', value: monthlyIncome }
            ]
        },
        { 
            name: labels[1], 
            value: Math.round(chartData[0][1]), 
            color: COLORS.giving, 
            category: 'giving' 
        },
        { 
            name: 'Taxes', 
            value: taxTotal, 
            color: COLORS.cost, 
            category: 'cost',
            breakdown: taxBreakdown
        },
    ];

    return (
        <div className="chart-container">
            <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                    <Pie
                        activeShape={renderActiveShape}
                        data={data}
                        cx="50%"
                        cy="40%"
                        innerRadius={60}
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="value"
                        activeIndex={[0, 1, 2]}
                        animationDuration={300}  // in milliseconds
                    >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color} />
                        ))}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
        </div>
    );
};

export default TakehomePayChart;
